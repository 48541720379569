<template>
  <div class="residentInfo">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :beforeList="beforeList"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <v-button text="导入" :width="112" @click="toExport"
      /></template>
      <template #searchSlot>
        <v-input label="真实姓名" v-model="searchParam.userName" />
        <v-cascader
          label="房号"
          placeholder="默认表示全部"
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
          @change="sectionChange"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" @click="toEdit(scope.row)" />
        <v-button text="删除" @click="deleteData(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getQueryPdfUserList, getBuildListURL, delPdfUser } from "./api.js";
import { sexMap } from "./map.js";
import { _localStorage } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  name: "residentInfo",
  data() {
    return {
      extraParams: {
        tenantId: "",
      },
      authSpaceIds: [], //id集合
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      sectionOps: [], //房号数据
      spacePathIds: [], // 添加treeIds的数据
      searchParam: {},
      tableUrl: getQueryPdfUserList,
      headers: [
        {
          prop: "houseName",
          label: "房号",
          show: true,
        },
        {
          prop: "phone",
          label: "手机号码",
          show: true,
        },

        {
          prop: "realName",
          label: "真实姓名",
          show: true,
        },
        // {
        //   prop: "gender",
        //   label: "性别",
        //   show: true,
        //   formatter: (row, prop) => {
        //     return sexMap[row[prop]] || "--";
        //   },
        // },
        {
          prop: "name",
          label: "身份证件类型",
          show: true,
        },
        {
          prop: "cardNum",
          label: "身份证号码",
          show: true,
        },
      ],
    };
  },
  watch: {
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.searchParam.spaceId = val.map((a) => a[a.length - 1]).join(",");
        console.log(this.searchParam.spaceId);
      },
      deep: true,
    },
  },
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.$store.commit("app/setAccountInfo", userInfo);
    }
    this.getBuildList();
  },
  computed: {
    ...mapState("app", ["accountInfo"]),
  },
  mounted() {},
  methods: {
    beforeList() {
      this.extraParams.tenantId = this.accountInfo.tenantId;
    },
    async getBuildList() {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=1`
      );
      if (res.code === 200) {
        // type = 1 居民 2 商户
        // const maxLevel = type === 4 ? 3 : 2;
        this.sectionOps = this.dealDataAddParentIds(res.data);
        console.log("this.sectionOps----->", this.sectionOps);
      }
    },
    sectionChange(val) {},
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    //编辑
    toEdit(row) {
      this.$router.push({
        name: "peopleHouseEditResidentInfo",
        query: {
          id: row.id,
        },
      });
    },
    deleteData(row) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          id: row.id,
        };
        this.$axios.post(delPdfUser, this.$qs.stringify(params)).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.$refs.list.getList();
          }
        });
      });
    },
    toExport() {
      this.$router.push({
        name: "peopleHouseImportResidentInfo",
      });
    },
  },
};
</script>
<style lang="less">
.el-cascader__dropdown {
  .el-cascader-panel {
    min-height: 300px !important;
    max-height: 600px !important;
  }
}
</style>
<style lang="less" scoped>
.residentInfo {
  box-sizing: border-box;
  height: 100%;
  /deep/ .v-table .opera-btn {
    width: 100% !important;
    display: flex;
    .v-button {
      margin: 0 4px;
    }
    .el-button--primary {
      width: 78px;
      height: 24px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #2f7cff;
      font-size: 12px;
      font-weight: 400;
      color: #2f7cff;
    }
  }
}
</style>
